export const responsiveConfig = (elementsToShow: number = 3) => [
  {
    breakpoint: 1300,
    settings: {
      slidesToShow: elementsToShow > 5 ? 5 : elementsToShow,
      slidesToScroll: 1,
      autoplay: true,
    },
  },
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: elementsToShow > 3 ? 3 : elementsToShow,
      slidesToScroll: 1,
      autoplay: true,
    },
  },
  {
    breakpoint: 600,
    settings: {
      slidesToShow: elementsToShow > 3 ? 3 : elementsToShow,
      slidesToScroll: 1,
      autoplay: true,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: elementsToShow > 3 ? 3 : elementsToShow,
      slidesToScroll: 3,
      autoplay: true,
    },
  },
];
export const mockSliders = [
  {
    status: 'published',
    items: [
      {
        name: "Feels Like the 90's",
        redirectState: 'main.views.player-playlist',
        background: {
          src: 'https://img-sostereo.sostereo.com/sostereo-images-production/d0c45dbdc7b399a18604f55587e2dcad0286a82e@xlarge.jpg',
        },
        stateParams: {
          slug: 'feels-like-the-90-s-martin',
        },
      },
      {
        name: 'Happy Soul',
        redirectState: 'main.views.player-playlist',
        background: {
          src: 'https://img-sostereo.sostereo.com/sostereo-images-production/44fb14870e99aa83d9bccf68bc6caaac9e6f3419@xlarge.jpg',
        },
        stateParams: {
          slug: 'happy-soul-martin',
        },
      },
      {
        name: 'Upbeat, Fun & Young',
        redirectState: 'main.views.player-playlist',
        background: {
          src: 'https://img-sostereo.sostereo.com/sostereo-images-production/ab81472333adc4b90cad150770962282536aa349@xlarge.jpg',
        },
        stateParams: {
          slug: 'upbeat-fun-young-martin',
        },
      },
      {
        name: 'Hip Hop Gems',
        redirectState: 'main.views.player-playlist',
        background: {
          src: 'https://img-sostereo.sostereo.com/sostereo-images-production/nufPAag@xlarge.jpg',
        },
        stateParams: {
          slug: 'hip-hop-gems-nfl-martin',
        },
      },
      {
        name: 'Swagger Rock',
        redirectState: 'main.views.player-playlist',
        background: {
          src: 'https://img-sostereo.sostereo.com/sostereo-images-production/bF36Uv7@xlarge.jpg',
        },
        stateParams: {
          slug: 'swagger-rock-nfl-martin',
        },
      },
      {
        name: 'Funky Beats',
        redirectState: 'main.views.player-playlist',
        background: {
          src: 'https://img-sostereo.sostereo.com/sostereo-images-production/bYN4xB2@xlarge.jpg',
        },
        stateParams: {
          slug: 'funky-beats-nfl-martin',
        },
      },
    ],
    title: 'Featured Playlists',
    description: 'A series of our best playlist or something.',
    kind: 'playlist',
    settings: {
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: responsiveConfig,
    },
    priority: 1,
  },
  {
    status: 'published',
    items: [
      {
        name: 'Jessica Childress',
        background: {
          src: 'https://s3-us-west-2.amazonaws.com/sostereo-covers/2123421864365920.jpg',
        },
        redirectState: 'main.views.player-artist',
        stateParams: {
          artistSlug: 'jessica-childress',
        },
        itemId: '5b730cc98523610500a3151a',
      },
      {
        itemId: '5ca3b12c4582b905003e65dd',
        name: 'Poldoore',
        redirectState: 'main.views.player-artist',
        background: {
          src: 'https://img-sostereo.sostereo.com/sostereo-images-production/03dd5d26cddbb66a582e7a6e64a5d47819406dcd@large.jpg',
        },
        stateParams: {
          artistSlug: 'poldoore',
        },
      },
      {
        name: 'Phinisey',
        background: {
          src: 'https://s3-us-west-2.amazonaws.com/sostereo-covers/phinisey.jpg',
        },
        redirectState: 'main.views.player-artist',
        stateParams: {
          artistSlug: 'phinisey',
        },
        itemId: '5adf63d26ee62b2c0931ac19',
      },
      {
        name: 'Dan Sultan',
        background: {
          src: 'https://s3-us-west-2.amazonaws.com/sostereo-covers/10156720917473958.jpg',
        },
        redirectState: 'main.views.player-artist',
        stateParams: {
          artistSlug: 'dan-sultan',
        },
        itemId: '5ac776202f6c4d7c5bbf70a1',
      },
      {
        name: 'The Well Pennies',
        background: {
          src: 'https://s3-us-west-2.amazonaws.com/sostereo-images/LSryAAp.jpg',
        },
        redirectState: 'main.views.player-artist',
        stateParams: {
          artistSlug: 'the-well-pennies',
        },
        itemId: '5b733fd78523610500a3155d',
      },
      {
        name: 'Desmond Cheese',
        background: {
          src: 'https://s3-us-west-2.amazonaws.com/sostereo-covers/desmond-cheese.jpg',
        },
        redirectState: 'main.views.player-artist',
        stateParams: {
          artistSlug: 'desmond-cheese',
        },
        itemId: '58b708e1f0c9da1a2389b19c',
      },
      {
        name: 'The Indien',
        background: {
          src: 'https://s3-us-west-2.amazonaws.com/sostereo-covers/the-indien.jpg',
        },
        redirectState: 'main.views.player-artist',
        stateParams: {
          artistSlug: 'the-indien',
        },
        itemId: '5b240673d5f3680600eaefef',
      },
      {
        name: 'rad cat',
        background: {
          src: 'https://s3-us-west-2.amazonaws.com/sostereo-covers/1174469142577355.jpg',
        },
        redirectState: 'main.views.player-artist',
        stateParams: {
          artistSlug: 'rad-cat',
        },
        itemId: '5ae3ac0927b8ff2d161a0617',
      },
      {
        name: 'Parson Jones',
        background: {
          src: 'https://s3-us-west-2.amazonaws.com/sostereo-covers/parson-jones.jpg',
        },
        redirectState: 'main.views.player-artist',
        stateParams: {
          artistSlug: 'parson-jones',
        },
        itemId: '5a0a0f2c9a9e3fcf4408e38f',
      },
    ],
    title: 'Featured Artists',
    description: 'IDK',
    kind: 'artist',
    settings: {
      slidesToShow: 2,
      slidesToScroll: 2,
      responsive: responsiveConfig,
    },
    priority: 2,
  },
];

export const DiscoverMockSlider = {
  // itemId: '5c1ab00c26f5750500ef0962'
  name: 'Epic trap instrumentals',
  background: {
    src: 'https://img-sostereo.sostereo.com/sostereo-images-production/7eaf06f2531ae712c8a15b2c99104750ac941471@xlarge.jpg',
  },
  redirectState: 'main.views.player-playlist',
  stateParams: {
    slug: 'epic-trap-instrumentals-martin',
  },
};
